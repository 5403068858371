<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'My Account': 'Minha Conta',
      'Username': 'Usuário',
      'Name': 'Nome',
      'Cellphone': 'Celular',
      'Password': 'Senha',
    },
    es: {
      'My Account': 'Mi Cuenta',
      'Username': 'Nombre de Usuario',
      'Name': 'Nombre',
      'Cellphone': 'Celular',
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('My Account') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="pt-3 px-3">
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.company.name }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">Razão Social</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.name }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">Nome Fantasia</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.company.cnpj }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">CNPJ</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.responsible }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">Responsável</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.email }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">E-mail</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">{{ account.user.cellphone }}</p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">Celular</p>
          </div>
          <div class="text-center-sm mb-3">
            <p class="mb-0 font-weight-normal notranslate">
              {{ account.user.company.city }}/{{ account.user.company.state }}
            </p>
            <p class="mb-0 text-uppercase font-weight-normal font-size-12 card-title-desc">Cidade</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>